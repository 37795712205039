import {
  Container,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import nav_logo from "../assets/MTGPlace.png";
import { LinkContainer } from "react-router-bootstrap";
import { Outlet } from "react-router-dom";
import PopupAd from "../common/PopupAd";

const MtgNav = () => {
  return (
    <>
      <Navbar fixed="top" className="level-0 py-0">
        <Container>
          <LinkContainer to="/Home">
            <Navbar.Brand>
              <img className="nav-service-logo" src={nav_logo} alt="logo" />
            </Navbar.Brand>
          </LinkContainer>
          <Nav className="fw-bold me-auto align-items-center">
            <LinkContainer to="/Podcasts">
              <Nav.Link className="text-mtg-orange">Podcasts</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav>
            <LinkContainer to="/lifeCounter">
              <Nav.Link className="text-mtg-orange">
                <OverlayTrigger
                  className="d-none d-md-inline"
                  key={"life-counter"}
                  placement="bottom"
                  overlay={
                    <Tooltip id={"tooltip-life-counter"}>
                      <strong>Life Counter</strong>
                    </Tooltip>
                  }
                >
                  <i
                    className="bi bi-heart-pulse-fill"
                    style={{ fontSize: "1.5em", color: "var(--primary)" }}
                  ></i>
                </OverlayTrigger>
              </Nav.Link>
            </LinkContainer>
            <Nav.Link
              className="p-2 m-auto"
              href="https://www.youtube.com/channel/UCS9tOSBjBZk8oOt_im6OKkA"
            >
              <i
                className="bi bi-youtube"
                style={{ fontSize: "1.5em", color: "var(--primary)" }}
              ></i>
            </Nav.Link>
            <Nav.Link
              className="p-2 m-auto"
              href="https://discord.gg/hGUbspVfcZ"
            >
              <i
                className="bi bi-discord"
                style={{ fontSize: "1.5em", color: "var(--primary)" }}
              ></i>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <div className="margin-top-for-nav p-0">
        <Outlet />
      </div>
    </>
  );
};

export default MtgNav;
